<template>
  <div>
    <v-layout class="mainfont" wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12 v-if="list.length > 0">
        <v-layout wrap pt-8 pr-11 justify-end>
          <v-flex xl3 lg2 md3 pb-7 pr-4 sm3 xs12 align-self-center>
            <v-text-field
              v-model="searchKeyword"
              solo
              dense
              hide-details
              clearable
              color="black"
              label="Search User"
              class="custom-text-field rounded-lg"
            >
              <template v-slot:label>
                <span
                  class="custom-label-text"
                  style="color: black; font-size: 14px"
                  >Search User</span
                >
              </template>
            </v-text-field>
          </v-flex>

          <v-flex xl3 lg2 md3 pb-7 pr-2 sm3 xs12 align-self-center>
            <v-autocomplete
              solo
              dense
              color="black"
              :items="itemArray"
              v-model="status"
              item-text="name"
              item-color="#FF1313"
              hide-details
              clearable
              label=" Status"
              class="custom-autocomplete rounded-lg"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
              <template v-slot:label>
                <span
                  class="custom-label-text"
                  style="color: black; font-size: 14px"
                >
                  Status</span
                >
              </template>
            </v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout wrap class="mainbg2" justify-center>
          <v-flex xs11>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      color="red"
                      class="mainfont"
                      style="
                        background-color: #13736f;
                        color: white;
                        font-size: 15px;
                        font-weight: lighter;
                      "
                    >
                      Name
                    </th>
                    <th
                      class="mainfont"
                      style="
                        background-color: #13736f;
                        color: white;
                        font-size: 15px;
                        font-weight: lighter;
                      "
                    >
                      E-mail
                    </th>

                    <th
                      class="mainfont"
                      style="
                        background-color: #13736f;
                        color: white;
                        font-size: 15px;
                        font-weight: lighter;
                      "
                    >
                      Phone
                    </th>
                    <th
                      class="mainfont"
                      style="
                        background-color: #13736f;
                        color: white;
                        font-size: 15px;
                        font-weight: lighter;
                      "
                    >
                      Role
                    </th>
                    <th
                      class="mainfont"
                      style="
                        background-color: #13736f;
                        color: white;
                        font-size: 15px;
                        font-weight: lighter;
                      "
                    >
                      Plantation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    @click="goToDetailedView(item._id)"
                    v-for="(item, i) in list"
                    :key="i"
                    style="cursor: pointer"
                  >
                    <td v-if="item.role === 'Farmer'">
                      <span>{{ item.farmerId.name }}</span>
                    </td>

                    <td v-else-if="item.role === 'Trader'">
                      <span>{{ item.traderId.name }}</span>
                    </td>

                    <td v-else-if="item.role === 'Customer'">
                      <span>{{ item.customerId.name }}</span>
                    </td>

                    <td v-if="item.role === 'Farmer'">
                      <span>{{ item.farmerId.email }}</span>
                    </td>

                    <td v-else-if="item.role === 'Trader'">
                      <span>{{ item.traderId.email }}</span>
                    </td>

                    <td v-else-if="item.role === 'Customer'">
                      <span>{{ item.customerId.email }}</span>
                    </td>

                    <td v-if="item">
                      <span>
                        {{ item.phone }}
                      </span>
                    </td>

                    <td v-else>
                      <span> Nil </span>
                    </td>

                    <td v-if="item">
                      <span>
                        {{ item.role }}
                      </span>
                    </td>

                    <td v-else>
                      <span> Nil </span>
                    </td>
                    <td v-if="item">
                      <span v-if="item.farmerId">
                        <span v-if="item.farmerId.plantation">{{
                          item.farmerId.plantation
                        }}</span>
                      </span>
                      <span v-else>Nil</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout class="mainbg2" pt-4 pb-12 wrap justify-center>
          <v-flex xs12 align-self-center>
            <div>
              <v-pagination
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                :length="pages"
                :total-visible="7"
                v-model="currentPage"
                color="#13736f"
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 text-center v-else>
        <span
          class="mainfont"
          style="color: black; font-size: 20px; font-weight: bold"
          >No User's Found!</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import { mdiChevronDown } from "@mdi/js";
export default {
  data() {
    return {
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      pages: 0,
      dialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      customHeight: 550,
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      itemArray: ["Pending", "Rejected", "Inactive", "Active"],
      list: [],
      searchKeyword: null,
      status: null,
      role: null,
    };
  },

  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
  },


  watch: {
    status() {
      // if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    searchKeyword() {
      // this.currentPage = parseInt(localStorage.getItem("currentPage"));
      if (this.currentPage > 1) this.currentPage = 1;

      this.getData();
    },
   
    currentPage() {
      this.getData();
    //   localStorage.setItem("currentPage", this.currentPage);
    },
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios
        .get("/admin/users/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            status: this.status,
            searchKeyword: this.searchKeyword,
            page: this.currentPage,
            limit: 10,
            role: "Farmer",
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    goToDetailedView(itemId) {
      this.$router.push({
        path: "/DetailedViewPage",
        query: {
          id: itemId,
          currentPage: this.currentPage,
        },
      });
    },
  },
};
</script>
  
  <style>
.mainfont {
  font-family: mainfont;
  font-size: 17px;
  color: #000000;
  text-transform: none;
}
.custom-text-field .v-input__control {
  background-color: white;
  color: black;
}

/* Custom style for the label text */
.custom-label-text {
  color: black;
}
.custom-autocomplete .v-input__control {
  background-color: white;
  color: black;
}

/* Custom style for the label text */
.custom-label-text {
  color: black;
}
.custom-autocomplete .v-input__control {
  background-color: white;
  color: black;
}

/* Custom style for the label text */
.custom-label-text {
  color: black;
}
.v-pagination .v-pagination__item:not(.v-pagination__item--active) {
  color: #005f32; /* Replace with your desired color */
}

.v-pagination .v-pagination__item--active {
  background-color: #005f32; /* Replace with your desired color */
  color: white; /* Replace with your desired text color for active page */
}
</style>
  